import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import { Col } from 'react-bootstrap'
import { SingleSection } from '../components/sections'

const Obiettivi = () => (
  <Layout frame='objNav'>

    <SingleSection>
        <Col md={12} className='text-center heading-section'>
            <h3>Obiettivi</h3>
            <p>Attivare competenze e metodologie innovative per il trattamento delle informazioni, a supporto del controllo,
                del contenimento dei rischi e delle decisioni.
            </p>
            <p>Legare l'aspetto di gestione (IAS) con quello di ricerca (SUPSI) tramite un accordo di convenzione che
                permette ad entrambe le istituzioni di crescere ed avere impatti positivi nell'acquisizione di competenze.
            </p>
        </Col>
    </SingleSection>

  </Layout>

)

export default Obiettivi
